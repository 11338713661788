html,body{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}
.highlighttext {
    color: #aa6300 !important;
}
.JobCard p {
    margin:0 !important;
    display: inline;
}
.JobCard strong, h1, h2, h3, h4, h5, h6 {
    font-weight: normal !important;
    font-size: inherit;
}
.JobCard br {
    display: none !important;
}
.fadein-fast {
    -webkit-animation: fadein 100ms; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 100ms; /* Firefox < 16 */
        -ms-animation: fadein 100ms; /* Internet Explorer */
         -o-animation: fadein 100ms; /* Opera < 12.1 */
            animation: fadein 100ms;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.ngx-overlay{
    background-color: rgba(40, 40, 40, 0.2) !important;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #05F1BE;
  border-color: #05F1BE;
  font-weight: 600!important;
}
.btn-success {
    color:#000;
    font-weight: 600!important;
    border-color: #05F1BE;
    background-color: #05F1BE;
  }
  .btn-success:hover {
    color:#000;
    background-color: #04cea2;
    border-color:#04cea2;
    font-weight: 600!important;
  }
  .btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #0e1b5d;
    border-color: #0e1b5d;
    box-shadow: 0 0 0 .2rem rgba(38,143,255,.5);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0e1b5d;
  border-color: #0e1b5d;
}
  .btn-primary {
    background-color: #0e1b5d;
    border-color:#0e1b5d;
  }
  .btn-primary:hover {
    background-color:#0a154b;
    border-color:#0a154b;
  }
  .btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #0e1b5d!important;
    border-color: #0e1b5d!important;
    box-shadow: 0 0 0 0.2rem rgba(10,20,73,.5)!important;
}
  .btn-info {
    background-color: #006c7c;
    border-color: #006c7c;
  }
  .btn-info:hover {
    background-color: #048ea3;
    border-color: #048ea3;
  }
  .hyperlink-text {  
    text-decoration: underline!important;
    color: #425180!important;
    font-weight: 700!important;
    font-size: 14px!important;
    cursor: pointer!important;
  }
  .form-check {
    line-height: 30px;
}
.form-check .form-check-input {
  height: 20px;
  width: 20px;
}
.form-check .form-check-label {
  margin-left: 8px;
}
.SeoDynamicPageContent img{
  width: 8%;
}
.SeoDynamicPageContent h1{
  font-size: 18px;
  color: #223a6b;
}
.SeoDynamicPageContent h1 strong{
  font-size: 18px;
  font-weight: 400;
  color: #223a6b;
}
.SeoDynamicPageContent p {
  font-size: 12px;
  margin-bottom: 0px;
  color: #757575;
}
.SeoDynamicPageContent a {
  color:#0e1b5c;
  text-decoration: underline;
}
.SeoDynamicPageContent a:hover {
  color:#076edd;
}
.SeoDynamicPageContent img {
  width: 25px;
  height: 25px;
}
.footerNav {
  width: 100%;
  padding: 25px 0px 25px 0px;
}
.footerNav span {
  margin: 0;
  color: #ffffff;
  padding: 0 0 10px 8px;
}
.footerNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footerNav ul li {
  font-size: 14px;
  font-weight: 600!important;
}
.footerNav ul li a {
  display: block;
  color: #ffffff;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
}
.footerNav ul li a:hover {
  display: block;
  color: #d6d6d6;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
}
.post-view-text, .post-view-text p {
  margin: 0!important;
}
.footerLogos a {
  margin: 0 5px;
  display: inline-block;
}
.footerLogos a img{
  width: 91px;
  height: 42px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .SeoDynamicPageContent p {
    line-height: 25px;
  }
  .SeoDynamicPageContent h1 {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px){
  .SeoDynamicPageContent p {
    font-size: 14px
  }
}